import classes from "./ShareModal.module.scss";
import { ReactComponent as Copy } from "assets/images/copy.svg";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Close } from "assets/images/close.svg";
import { Input } from "containers/input";
import StyledButton from "../Buttons/StyledButton";
import CustomDatePicker from "../CustomDatePicker";
import createShareItemMutation from "graphql/mutations/CreateShareItemMutation";
import CopyInput from "../Input/CopyInput";
import { STUDIO_URL } from "constants/env";
import ImgModal from "../ImgModal/ImgModal";
// import { showSuccessToast } from "@master-wizr/toast";

function Sharing({ handleClose, itemId, itemName, onUrlCreated, ...others }) {
  const [state, setState] = useState({
    itemId,
    ...others,
    start: new Date(),
    end: new Date(),
    securityPass: "",
    emailsToShare: "",
  });
  const [url, setUrl] = useState("");
  const inputRef = useRef(null);
  function copyUrl(e) {
    inputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }

  const handleState = e => {
    const { name, value } = e.target;
    setState(prev => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const [addExpiry, setAddExpiry] = useState(false);
  const handleAddExpiry = () => {
    setAddExpiry(prev => !prev);
  };
  const generateLink = type => {
    const { start, end, emailsToShare, ...values } = { ...state };

    const emails = emailsToShare.split(",").map(email => email.trim());
    values.emails = !!emails ? emails : undefined;
    values.securityPass = values.securityPass.trim();
    values.activeStartDate = start;
    values.activeEndDate = end;

    values.itemName = itemName;
    createShareItemMutation(values, ({ slug }) => {
      if (type === "generate") {
        if (itemName === "magazine") {
          onUrlCreated(`${STUDIO_URL}/magazine/share/${slug}`);
        } else {
          onUrlCreated(`${STUDIO_URL}/share/${slug}`);
        }
      } else {
        setUrl(`${STUDIO_URL}/share/${slug}`);
      }
    });
  };

  return (
    <ImgModal extraClass="sharing-first-modal" state={true}>
      <div>
        <p style={{ marginTop: 40 }}>Choose who can view</p>
        {/* <div className={`${classes.inputGroup} inputGroup`}>
          <input
            type="text"
            name="emailsToShare"
            value={state.emailsToShare}
            onChange={handleState}
            placeholder="Add emails separated by comma "
          />
        </div> */}
        {/* share by email to be worked after launch */}
        <div className={`${classes.expirylabel} checkbox-label`}>
          <input
            type="checkbox"
            checked={addExpiry}
            onChange={handleAddExpiry}
            className={`${classes.checkboxinput}`}
          />{" "}
          <p>Schedule expiry duration</p>
        </div>
        {addExpiry && (
          <div style={{width:"100%"}}>
            <CustomDatePicker {...{ ...state, setState }} />
          </div>
        )}
        <p>Security Password</p>
        <CopyInput
          name="securityPass"
          type="text"
          value={state.securityPass}
          onChange={handleState}
          placeholder="If no security pass is added, anyone can view the content"
          className="inputGroup"
          icon={true}
        />
        <div className={classes["bottombtn"]}>
          <button onClick={handleClose} submitbutton={"true"}>
            Cancel
          </button>
          <button
            onClick={() => generateLink("generate")}
            submitbutton={"true"}
          >
            Generate link
          </button>
        </div>
      </div>
    </ImgModal>
  );
}
export default Sharing;

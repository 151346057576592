import graphql from "babel-plugin-relay/macro";

const profilesByUserId = graphql`
  query profilesByUserIdQuery($profilesByUserIdId: ID!) {
    profilesByUserId(id: $profilesByUserIdId) {
      ... on ListOfProfiles {
        profiles {
          _id
          userId
          accountId
          accountRole
          profilePhoto
          locale
          accountInfo {
            _id
            name
            theme
            isActive
            teamInfo {
              _id
              name
              tier
              modules
              seats
              occupied
              available
            }
            licenseInfo {
              licenseType
            }
          }
          userInfo {
            _id
            firstName
            lastName
            email
          }
          accessedModules {
            name
            role
            tier
          }
          accessedPortals {
            portalId
            portalRole
            portalInfo {
              _id
              accountId
              name
              creator
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export default profilesByUserId;
